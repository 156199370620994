<template>
  <div>
    <ViewTransactionPopup />

    <refund-popup
      @refunded="handleRefunding('succeeded')"
      @failed="handleRefunding('failed')"
    />

    <content-section :spacing="false">
      <div class="content-spacing">
        <div class="grid grid-cols-1 mb-10 md:grid-cols-2">
          <div class="">
            <title-plus title="Transaction Management" :hide-plus="true" />
          </div>

          <div class="flex md:justify-end">
            <date-range-picker
              @apply-date="handleApplyDate($event)"
              @cancel-date="handleCancelDate($event)"
            />
          </div>
        </div>

        <div class="flex flex-col mt-4 mb-10 md:items-center md:flex-row">
          <card-tax-stat
            v-show="currentTab === 'credit'"
            title="Total Credit"
            :value="`${symbol} ${indexMetaData.summary.total_credit}`"
            variant="purple"
            class="mr-3"
          />
          <card-tax-stat
            v-show="currentTab === 'credit'"
            title="AVG Credit/Day"
            :value="`${symbol} ${indexMetaData.summary.avg_credit_per_day}`"
            variant="orange"
            class="mr-3"
          />
          <card-tax-stat
            v-show="currentTab === 'debit'"
            title="Total Debit"
            :value="`${symbol} ${indexMetaData.summary.total_debit}`"
            variant="green"
            class="mr-3"
          />
          <card-tax-stat
            v-show="currentTab === 'debit'"
            title="AVG Debit/Day"
            :value="`${symbol} ${indexMetaData.summary.avg_debit_per_day}`"
            variant="blue"
            class="mr-3"
          />
        </div>

        <div class="grid grid-cols-1 md:grid-cols-2">
          <div>
            <oto-search v-model="search" />
          </div>
          <div class="flex md:justify-end ">
            <transaction-tabs
              @debit="handleTabs($event)"
              @credit="handleTabs($event)"
              class="mr-2"
            />
            <export-as-dropdown />
          </div>
        </div>
      </div>

      <fsTableContainer class="vld-parent">
        <loading :active.sync="isLoading" :is-full-page="false" />

        <fsTable>
          <fsTableHead>
            <fsTableHeadItem
              v-for="(item, index) in currentTableHeaders"
              :key="`fs-table-header-${index}`"
              :item="item"
              @apply-sorting="handleSorting($event)"
            />
          </fsTableHead>

          <fsTableRow
            v-for="(item, index) in indexData"
            :key="`fs-table-row-${index}`"
          >
            <fsTableRowItem>
              <template v-if="currentTab === 'debit'">
                <router-link
                  class="font-bold text-blue-600 capitalize"
                  :to="{
                    name: 'ViewTrip',
                    params: { id: item.trip },
                  }"
                >
                  #{{ item.id | onlyLastFive }}
                </router-link>
              </template>

              <template v-if="currentTab === 'credit'">
                <div
                  @click="popup(item)"
                  class="font-bold text-blue-600 capitalize cursor-pointer"
                >
                  #{{ item.id | onlyLastFive }}
                </div>
                <router-view />
              </template>
            </fsTableRowItem>

            <fsTableRowItem>
              <template v-if="item.tax_plan">
                {{ item.tax_plan | onlyLastFive }}
              </template>
              <template v-else>
                --
              </template>
            </fsTableRowItem>

            <fsTableRowItem>
              <template v-if="item.user">
                <router-link
                  class="font-bold text-blue-600 capitalize"
                  :to="{
                    name: 'ViewRiderUserProfile',
                    params: { id: item.user.id },
                  }"
                >
                  {{ item.user.full_name }}
                </router-link>
              </template>
              <template v-else>
                --
              </template>
            </fsTableRowItem>

            <fsTableRowItem v-if="currentTab === 'credit'">
              <template v-if="item.user">
                {{ item.user.role_name }}
              </template>
              <template v-else>
                --
              </template>
            </fsTableRowItem>

            <fsTableRowItem>
              {{ $localTime(item.date) }}
            </fsTableRowItem>

            <fsTableRowItem>
              {{ item.payment_method }}
            </fsTableRowItem>

            <fsTableRowItem>
              {{ item.payment_for }}
            </fsTableRowItem>

            <fsTableRowItem>
              <span v-html="printAmount(item.payment_method, item.amount)" />
            </fsTableRowItem>

            <fsTableRowItem>
              <!-- <x-status
                :text="getTransactionStatus(item.status)"
                :variant="getTransactionStatusBadge(item.status)"
                profile="payment"
              /> -->
              <div class="flex items-center">
                <template v-if="currentTab === 'debit'">
                  <oto-eye-icon
                    v-tooltip.bottom="'View Invoice'"
                    @click="onInvoiceModalReq(item.trip, false)"
                  />
                </template>
                <template v-else>
                  <oto-eye-icon
                    v-tooltip.bottom="'View Invoice'"
                    @click="popup(item)"
                  />
                </template>

                <!-- <oto-download-icon
                  class="ml-2"
                  v-tooltip.bottom="'Download Invoice'"
                  @click="onInvoiceModalReq(item.trip, true)"
                /> -->
              </div>
            </fsTableRowItem>

            <template v-if="currentTab === 'debit'">
              <fsTableRowItem>
                <!-- <div class="flex items-center">
                <oto-edit-icon @click="edit(item)" />
                <MoreActionsDropdown
                  :currentVal="item.status"
                  :onOffEndpoint="getActionsEndpoint(item.id)"
                  actionOnKey="status"
                  actionOffKey="status"
                  actionOnVal="P"
                  actionOffVal="U"
                  @actionOn="item.status = 'P'"
                  @actionOff="item.status = 'U'"
                />
              </div> -->
                <div
                  class="refund-action"
                  :class="item.is_refunded ? 'refunded' : 'refund'"
                  @click="showRefundPopup(item)"
                >
                  {{ item.is_refunded ? 'Refunded' : 'Refund' }}
                </div>
              </fsTableRowItem>
            </template>
          </fsTableRow>
        </fsTable>

        <fs-table-foot
          :total-items="indexMetaData.count.total"
          :pagination-summary="paginationSummary"
        />
      </fsTableContainer>
    </content-section>

    <InvoiceModal :trip="invoiceModalDetails" :symbol="symbol" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { TransactionConfig } from '@/config/TransactionConfig'
import { EventBus } from '@/utils/EventBus'

import isLoadingMixin from '@/mixins/isLoadingMixin'
import queryStringMixin from '@/mixins/queryStringMixin'
import queryStringSearchMixin from '@/mixins/queryStringSearchMixin'
import queryStringFiltersMixin from '@/mixins/queryStringFiltersMixin'
import getIndexDataMixin from '@/mixins/getIndexDataMixin'
import fsTableUiMixin from '@/mixins/fsTableUiMixin'
import { resolveProp } from '@/utils'

export default {
  name: 'ViewOrganizationUserPayments',
  mixins: [
    isLoadingMixin,
    queryStringMixin,
    queryStringSearchMixin,
    queryStringFiltersMixin,
    getIndexDataMixin,
    fsTableUiMixin,
  ],
  components: {
    // BaseLayout: () => import('@/views/shared/BaseLayout.vue'),
    ContentSection: () => import('@/components/layout/ContentSection'),
    TitlePlus: () => import('@/components/ui/TitlePlus'),
    OtoEyeIcon: () => import('@/components/ui/OtoEyeIcon'),
    //  OtoEditIcon: ()=> import("@/components/ui/OtoEditIcon";)
    // OtoDownloadIcon: () => import('@/components/ui/OtoDownloadIcon'),
    DateRangePicker: () =>
      import('@/components/picker/date-range/DateRangePicker'),
    OtoSearch: () => import('@/components/ui/OtoSearch'),
    //  XStatus: ()=> import("@/components/badge/XStatus";)
    ExportAsDropdown: () => import('@/components/dropdown/ExportAsDropdown'),
    //  MoreActionsDropdown: ()=> import("@/components/dropdown/MoreActionsDropdown";)
    CardTaxStat: () => import('@/components/cards/TaxStat'),
    TransactionTabs: () => import('@/components/tabs/TransactionTabs'),
    ViewTransactionPopup: () =>
      import('@/views/transactions/ViewTransactionPopup'),
    //  ViewTransaction: ()=> import("@/views/transactions/ViewTransaction";)
    RefundPopup: () =>
      import('@/composites/transaction/revenue/index/RefundPopup.vue'),
    InvoiceModal: () => import('@/components/modals/InvoiceModal'),
  },
  data() {
    return {
      symbol: '$',
      indexMetaData: {
        summary: {
          total_credit: '0.00',
          avg_credit_per_day: '0.00',
          total_debit: '0.00',
          avg_debit_per_day: '0.00',
        },
        count: {
          total: 0,
        },
      },
      indexDataEndpoint: TransactionConfig.api.index,

      currentTab: 'debit',
      currentTableHeaders: [
        {
          name: 'Invoice ID',
          width: '10%',
          field: 'id',
          sort: false,
          order: '',
        },
        {
          name: 'Tax ID',
          width: '10%',
          field: 'tax',
          sort: null,
          order: '',
        },
        {
          name: 'Rider',
          width: '10%',
          field: 'user',
          sort: null,
          order: '',
        },
        {
          name: 'Payer Role',
          width: '10%',
          field: 'user',
          sort: false,
          order: '',
        },
        {
          name: 'Transaction Time',
          width: '15%',
          field: 'date',
          sort: true,
          order: '',
        },
        {
          name: 'Payment Method',
          width: '15%',
          field: 'payment',
          sort: true,
          order: '',
        },
        {
          name: 'Payment for',
          width: '10%',
          field: 'payment_for',
          sort: true,
          order: '',
        },
        {
          name: 'Amount',
          width: '10%',
          field: 'amount',
          sort: true,
          order: '',
        },
        {
          name: 'Invoice',
          width: '10%',
          field: null,
          sort: null,
          order: '',
        },
      ],
      invoiceModalState: false,
      invoiceModalDetails: {},
    }
  },
  created() {
    this.handleTabs('debit')
  },
  computed: {
    ...mapGetters('auth', ['organizationInfo']),
    id() {
      return this.$route.params.id
    },
  },
  async mounted() {
    // todo: remove the event listener
    // todo: create a refresh index data mixin ?
    this.symbol = this.organizationInfo.default_currency
      ? this.organizationInfo.default_currency.symbol
      : '$'
    window.addEventListener(TransactionConfig.events.refresh, () => {
      this.getIndexData(this.getQueryString)
    })
  },
  methods: {
    getTransactionStatus(status) {
      let statuses = {
        P: 'Paid',
        U: 'Unpaid',
      }
      return status in statuses ? statuses[status] : '-'
    },
    getTransactionStatusBadge(status) {
      let statuses = {
        P: 'green',
        U: 'red',
      }
      return status in statuses ? statuses[status] : 'red'
    },
    edit: function(item) {
      console.log(item)
      // EventBus.$emit(SubscriptionConfig.events.editingData, item);
      // dispatchEvent(new Event(SubscriptionConfig.events.sorToggle));
    },
    popup(item) {
      EventBus.$emit(TransactionConfig.events.viewingData, item)
      this.$modal.show(TransactionConfig.events.viewingData)
      // this.$router.push({ name: "ViewTransaction", params: { id: item.id } });
    },
    showRefundPopup(item) {
      if (item.is_refunded) {
        return
      }
      EventBus.$emit(TransactionConfig.events.refundPopup, item)
      this.$modal.show(TransactionConfig.events.refundPopup)
    },
    async handleRefunding(status = 'failed') {
      if (status === 'succeeded') {
        await this.getIndexData(this.getQueryString)
        this.$notify(
          {
            group: 'generic',
            type: 'success',
            title: 'Refunded',
            text: 'Successfully refunded to the rider.',
          },
          3000
        )
        return
      }
      // the error is being shown on the popup
      // this.$notify(
      //   {
      //     group: "generic",
      //     type: "danger",
      //     title: "Failed",
      //     text: "Failed to refund!"
      //   },
      //   3000
      // );
    },
    getActionsEndpoint(id) {
      return TransactionConfig.api.update(id)
    },
    printAmount(type, amount) {
      let color = type === 'Card' ? 'text-oGreen' : ''
      return `<span class="${color}">${this.symbol} ${
        amount ? parseFloat(amount).toFixed(2) : '0.00'
      }</span>`
    },
    handleTabs(tabId) {
      if (tabId === 'credit') {
        this.currentTableHeaders.splice(
          2,
          1,
          {
            name: 'Payer Name',
            width: '10%',
            field: 'user',
            sort: false,
            order: '',
          },
          {
            name: 'Payer Role',
            width: '10%',
            field: 'user',
            sort: false,
            order: '',
          }
        )
        // remove the actions column
        this.currentTableHeaders.pop()
        this.queryString.transaction_type = 'CREDIT'
        // adding a fake param to trigger the watchers
        // NOTE: this line is modified after copying from @/views/transactions/Transactions.vue
        this.queryStringOptions.append =
          '&_far=' +
          Math.random().toFixed(2) +
          '&franchise_user=' +
          this.$route.params.id
      }
      if (tabId === 'debit') {
        this.currentTableHeaders.splice(2, 2, {
          name: 'Rider',
          width: '10%',
          field: 'user',
          sort: false,
          order: '',
        })
        // add the actions column
        this.currentTableHeaders.push({
          name: 'Actions',
          width: '10%',
          field: null,
          sort: null,
          order: '',
        })
        this.queryString.transaction_type = 'DEBIT'
        // adding a fake param to trigger the watchers
        // NOTE: this line is modified after copying from @/views/transactions/Transactions.vue
        this.queryStringOptions.append =
          '&_han=' +
          Math.random().toFixed(2) +
          '&franchise_user=' +
          this.$route.params.id
      }
      this.currentTab = tabId
      console.log(this.queryString)
      this.getIndexData(this.getQueryString)
    },
    async onInvoiceModalReq(id, downloadOnly = false) {
      this.$notify(
        {
          group: 'generic',
          type: 'default',
          title: `Processing`,
          text: 'The action is being executed...',
        },
        600
      )

      await this.$http
        .get(this.$config.trip.api.single(id), {
          params: {
            preferred_currency: resolveProp(
              'fsTable.organizationCurrency.selected',
              this.$store.state
            ),
          },
        })
        .then((res) => {
          this.invoiceModalDetails = res.data

          if (downloadOnly === true) {
            if (this.$refs.invoiceModal) {
              this.$refs.invoiceModal.exportToPDF()
            }
          } else {
            this.$modal.show('invoiceModal')
          }
        })
        .catch((err) => {
          this.$notify(
            {
              group: 'generic',
              type: 'error',
              title: `Server Error [${err.response.status}]`,
              text: 'Unable to process the request!',
            },
            5000
          )
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.refund-action {
  color: #ffffff;
  width: 87px;
  height: 25px;
  padding: 2px;
  font-weight: 400;
  text-align: center;
  border-radius: 3px;
  cursor: pointer;

  &.refund {
    background-color: #ff5500;
  }

  &.refunded {
    background-color: #c7c7c7;
  }
}
</style>
